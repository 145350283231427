import React, { Component } from 'react'

export class about extends Component {
	render() {
		return (
			<div id="about">
				<div className="container">
					<div className="section-title text-center">
						<h2>Profissional</h2>
					</div>
					<div className="row">
						<div className="col-xs-12 col-md-6">
							<img src="img/about.jpg" className="center-block img-responsive" alt="Foto do Dr. Alan Trindade de Moraes"/>
						</div>
						<div className="col-xs-12 col-md-6">
							<div className="about-text">
								<p>
									<strong>Dr. Alan Trindade de Moraes</strong> | OAB/PR 92.279
									<br/><br/>
									Especialista em Direito Penal Econômico pela Faculdade de Direito da Universidade de Coimbra-Portugal, em Ciências Penais pela Universidade Estadual de Maringá e em Direito Constitucional pela Universidade Cândido Mendes. Graduado pela Universidade Paranaense.
									<br/><br/>
									Presidente da Comissão de Direitos Humanos (2025-2027), membro da Comissão de Criminalistas e da Comissão de Direito Corporativo da OAB Subseção de Maringá.
									<br/><br/>
									Premiado com o selo de Referência Nacional em Advocacia & Justiça, realizado pela ANCEC (Agência Nacional de Cultura, Empreendedorismo e Comunicação), em São Paulo, sendo qualificado como destaque no ano de 2022 como Referência Nacional na Advocacia, categoria Ouro.
									<br/><br/>
									Reconhecido pela pesquisa da Previlege Pesquisas como destaque regional e municipal "Melhores do Ano em 2021" na categoria Advogado.
									<br/><br/>
									Associado do IBCCRIM (Instituto Brasileiro de Ciências Criminais).
									<br/><br/>
									Associado da Associação Paranaense dos Advogados Criminalistas-APACRIMI/Associação Brasileira dos Advogados Criminalistas-ABRACRIM.
									<br/><br/>
									Membro do MINDJUS CRIMINAL, grupo de criminalistas que promovem a advocacia colaborativa, compartilhando conhecimento e desenvolvendo novas habilidades.
									<br/><br/>
									Colaborador do "Portal Jurídico MPJus", com colunas sobre Direito Penal, Direito Penal Econômico, Compliance e Direito Constitucional.
									<br/><br/>
									<a href="mailto:alan@trindademoraes.adv.br"><i className="fa fa-envelope-o"></i> alan@trindademoraes.adv.br</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default about
