import React from 'react'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from '../frontend/App';

const AppRouter = () => (
	<Router>
		<div>
			<Routes>
				<Route path="/" Component={App} />
			</Routes>
		</div>
	</Router>
);

export default AppRouter;
